<template>
  <div class="pa-2">
    <div class="text-h6 mb-4">
      Diagnostic information
    </div>

    <v-alert
      text
      type="warning"
      dense
      :icon="false"
    >
      <span>
        Never use this page without instruction from Evalmee support team. Your grade could be affected.
      </span>
    </v-alert>

    <!-- Information summary -->
    <div class="d-flex flex-column">
      <div class="py-2">
        <div class="text-subtitle-2">
          Events
        </div>
        <div class="d-flex justify-space-between align-center text-caption">
          <div>Not synchronized: {{ unsyncedEvents + unsyncedScreenshots }}</div>
          <eva-button
            very-dense
            @click="syncEvents"
            :disabled="unsyncedEvents + unsyncedScreenshots === 0"
            label="Synchronize"
            icon-left="mdi-sync"
          />
        </div>
      </div>

      <v-divider />

      <div class="py-2">
        <div class="text-subtitle-2">
          Answers
        </div>
        <div class="d-flex justify-space-between align-center text-caption">
          <div>Not synchronized: {{ stats.notSync }}</div>
          <eva-button
            very-dense
            @click="reSyncAll"
            :disabled="stats.notSync === 0"
            label="Resynchronize"
            icon-left="mdi-database-sync"
          />
        </div>
      </div>

      <v-divider />

      <div class="py-2">
        <div class="text-subtitle-2">
          Assets cache
        </div>
        <div class="d-flex justify-space-between align-center text-caption">
          <div>Files in cache: {{ cachedCount }}/{{ totalCount }}</div>
          <div>
            <eva-button
              very-dense
              class="mx-1"
              @click="checkCache"
              icon-left="mdi-refresh"
              label="Check"
            />
            <eva-button
              very-dense
              class="mx-1"
              @click="preloadDocuments"
              :disabled="cacheStatus?.allCached"
              label="Load"
              icon-left="mdi-cloud-download-outline"
            />
            <eva-button
              very-dense
              class="mx-1"
              @click="clearCache"
              color="error"
              icon-left="mdi-trash-can-outline"
              label="Clear"
            />
          </div>
        </div>
      </div>

      <v-divider />
    </div>

    <!-- Detailed information -->
    <div class="text-subtitle-2 mt-3">
      Detailed Information
    </div>
    <pre
      ref="diagnosticInfoPre"
      class="diagnostic-info-pre mt-1"
    >{{ fullDiagnosticInfo }}</pre>

    <div class="d-flex justify-end mt-2 gap-2">
      <eva-button
        dense
        :class="{ 'success--text': copied }"
        @click="copyDiagnosticInfo"
        :icon-left="copied ? 'mdi-check' : 'mdi-content-copy'"
        :label="copied ? 'Copied' : 'Copy all'"
      />
      <eva-button
        dense
        @click="shareDiagnosticInfo"
        icon-left="mdi-chat-outline"
        label="Send to support chat"
      />
    </div>
  </div>
</template>

<script>
import { useStudentEventRegisterer } from "@/composables/useRegisterStudentEvent"
import { useRoute } from "vue-router/composables"
import { mapActions, mapGetters } from "vuex"
import quizStudentMixin from "@/views/quizzes/quiz_student_mixin"
import { EventStorage } from "@/helpers/studentEventStore"
import { useSubjectCache } from "@/composables/cache/useSubjectCache"
import { computed, ref } from "vue"
import { useClipboard, useMemory } from "@vueuse/core"
import ChatSupportHelpers from "@/helpers/chat_support_helpers"
import trackingHelpers from "@/helpers/tracking_helpers"

export default {
  name: "ExamDiagnosticInfo",
  props: {
    quizId: {
      type: Number,
      required: true,
    },
    quizzesAttemptSummaryId: {
      type: Number,
      required: true,
    },
  },
  mixins: [quizStudentMixin],
  setup(props) {
    const quizIdFromRoute = parseInt(useRoute().params.quizId)
    const { unsyncedEvents, unsyncedScreenshots } = useStudentEventRegisterer(quizIdFromRoute)
    const { memory }  = useMemory()

    const diagnosticInfoPre = ref(null)
    const { copy, copied } = useClipboard()

    const {
      checkCache,
      preloadDocuments,
      clearCache,
      cacheStatus,
    } = useSubjectCache(props.quizId)

    const cachedCount = computed(() => {
      if (!cacheStatus.value || !cacheStatus.value.details) return 0
      return cacheStatus.value.details.filter(d => d.cached).length
    })

    const totalCount = computed(() => {
      if (!cacheStatus.value || !cacheStatus.value.details) return 0
      return cacheStatus.value.details.length
    })

    return {
      unsyncedEvents,
      unsyncedScreenshots,
      quizIdFromRoute,
      checkCache,
      preloadDocuments,
      clearCache,
      cacheStatus,
      cachedCount,
      totalCount,
      diagnosticInfoPre,
      copy,
      copied,
      memory,
    }
  },
  computed: {
    ...mapGetters([
      "areAllAnswersSynchronized",
      "answersSyncStats",
      "currentUser",
      "quizInstanceByQuizId",
    ]),
    stats() {
      return this.answersSyncStats(
        this.quiz,
        this.currentUser?.id
      ) || { sync: 0, notSync: 0 }
    },
    quizInstanceId() {
      return this.quizInstanceByQuizId(this.quizId, this.currentUser?.id)?.id
    },
    fullDiagnosticInfo() {
      // Create complete diagnostic information text representation
      const toMB = (v) => (v / 1024 / 1024).toFixed(2)

      const info = {
        timestamp: new Date().toISOString(),
        user: this.currentUser ? { email: this.currentUser.email } : null,
        memory: {
          usedJSHeapSize: `${toMB(this.memory?.usedJSHeapSize)} MB`,
          totalJSHeapSize: `${toMB(this.memory?.totalJSHeapSize)} MB`,
          jsHeapSizeLimit: `${toMB(this.memory?.jsHeapSizeLimit)} MB`,
        },
        q: {
          id: this.quizId,
          name: this.quiz.name,
        },
        ea: {
          id: this.quizzesAttemptSummaryId,
        },
        i: {
          id: this.quizInstanceId,
        },
        events: {
          unsyncedEvents: this.unsyncedEvents,
          unsyncedScreenshots: this.unsyncedScreenshots,
          total: this.unsyncedEvents + this.unsyncedScreenshots,
        },
        answers: this.stats,
        assetsCache: {
          cached: this.cachedCount,
          total: this.totalCount,
          details: this.formatCacheDetails(true),
        },
      }

      return JSON.stringify(info, null, 2)
    },
  },
  methods: {
    ...mapActions([
      "reSyncAllAnswers",
      "getAnswersFromLocalStorage",
    ]),
    reSyncAll() {
      this.getLocalAnswers()
      this.reSyncAllAnswers({
        quiz: this.quiz,
        user: this.currentUser,
        quizzesAttemptSummary: this.quizzesAttemptSummary,
      })
    },
    getLocalAnswers() {
      if (!this.quizInstanceId) return
      this.getAnswersFromLocalStorage({ quiz_id: this.quizId, id: this.quizInstanceId })
    },
    async syncEvents() {
      console.debug("Start syncing events")
      const eventStorage = new EventStorage(this.quizId)
      eventStorage.syncAllEvents()
        .then(() => {
          console.debug("Events synced")
        })
        .catch((e) => {
          console.debug("Events sync failed", e)
        })
    },
    getFilename(url) {
      try {
        return decodeURIComponent(url).split("/").pop()
      } catch {
        return url.split("/").pop()
      }
    },
    formatCacheDetails(asArray = false) {
      if (!this.cacheStatus || !this.cacheStatus.details) return asArray ? [] : ""

      if (asArray) {
        return this.cacheStatus.details.map(doc => ({
          url: doc.url,
          cached: doc.cached,
          filename: this.getFilename(doc.url),
        }))
      }

      // Format texte simple
      return this.cacheStatus.details.map(doc => {
        const status = doc.cached ? "Cached" : "Not cached"
        return `${status} - ${doc.url}`
      }).join("\n")
    },
    copyDiagnosticInfo() {
      this.copy(this.fullDiagnosticInfo)

      // Remet l'état copié à false après 2 secondes
      setTimeout(() => {
        this.copied = false
      }, 2000)
    },
    shareDiagnosticInfo() {
      trackingHelpers.trackEvent("quizDiagnosticInfoShared", this.fullDiagnosticInfo)
      ChatSupportHelpers.sendCrispMessage(`\`\`\`${this.fullDiagnosticInfo}\`\`\``)
    },
  },
  mounted() {
    this.checkCache()
  },
}
</script>

<style scoped>
.diagnostic-info-pre {
  max-height: 300px;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px;
  border-radius: 4px;
  font-family: monospace !important;
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 0.75rem;
}
</style>
